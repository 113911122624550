<template>
  <div :style="style" class="layout-cell">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { isNumber } from "@horizon56/utils";

type LayoutCellProps = {
  w?: number;
  fixedWidth?: number;
  shrink?: boolean;
  offsetBefore?: number;
  offsetAfter?: number;
};
const props = withDefaults(defineProps<LayoutCellProps>(), { shrink: true });

const fractionOrPercentage = (nr?: number) => (isNumber(nr) ? (nr <= 1 ? nr * 100 : nr) : nr);

const style = computed(() => {
  const requestedWidth = fractionOrPercentage(props.w);
  const requestedOffsetBefore = fractionOrPercentage(props.offsetBefore);
  const requestedOffsetAfter = fractionOrPercentage(props.offsetAfter);

  const width = isNumber(props.fixedWidth) ? `${props?.fixedWidth}px` : "auto";
  const shrink = isNumber(props.fixedWidth) ? 0 : props.shrink === undefined ? "unset" : props.shrink ? 1 : 0;
  const flex =
    !isNumber(props.fixedWidth) && isNumber(requestedWidth)
      ? `1 1 calc(${requestedWidth}% - var(--layout-wrapper-gap))`
      : `1 1 ${width}`;
  const maxWidth = isNumber(props.fixedWidth) ? width : isNumber(requestedWidth) ? `${requestedWidth}%` : "unset";
  const offsetBefore = isNumber(requestedOffsetBefore) ? `${requestedOffsetBefore}%` : "0px";
  const offsetAfter = isNumber(requestedOffsetAfter) ? `${requestedOffsetAfter}%` : "0px";
  return `
    --layout-cell-flex: ${flex};
    --layout-cell-width: ${width};
    --layout-cell-shrink: ${shrink};
    --layout-cell-max-width: ${maxWidth};
    --layout-cell-offset-before: ${offsetBefore};
    --layout-cell-offset-after: ${offsetAfter};
    `;
});
</script>

<style lang="scss" scoped>
.layout-cell {
  display: flex;
  flex-flow: column;
  width: var(--layout-cell-width);
  flex: var(--layout-cell-flex);
  flex-shrink: var(--layout-cell-shrink);
  max-width: var(--layout-cell-max-width);
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: var(--layout-cell-offset-before);
  margin-right: var(--layout-cell-offset-after);

  :deep(> *) {
    width: 100%;
  } // 5.33
}
</style>
